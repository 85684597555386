import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn, CanActivateChildFn } from '@angular/router';
import { SessionService } from '../../services/session.service';

@Injectable()
export class AuthenticatedGuard {

   public static readonly canActivate: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      const sessionService = inject(SessionService);

      const isAuthenticated = sessionService.isAuthenticated;

      if (!isAuthenticated) {
         await sessionService.loginAsync(state.url);
      }

      return isAuthenticated;
   };

   public static readonly canActivateChild: CanActivateChildFn = (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      return AuthenticatedGuard.canActivate(childRoute, state);
   };
}
